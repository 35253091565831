import {
  getFirestore,
  collection,
  query,
  limit,
  getDocs,
  getDoc,
  orderBy,
  where,
  addDoc,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  writeBatch,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';

import { uploadBytesResumable, getStorage, ref, getDownloadURL } from 'firebase/storage';
import {
  getDeviceInfo,
  generateMonthStrings,
  generateHalfYearMonthStrings,
  getBoundaryDates,
} from 'src/utils/my';

// revalidateIfStale (boolean): 當設定為 false 時，如果數據是「陳舊的」（stale），即已經被獲取過一次，並且可能不是最新的，SWR 將不會在初次掛載組件時自動重新驗證（fetch）數據。這有助於避免不必要的網絡請求，尤其是當你確定數據不太可能變化時。
// revalidateOnFocus (boolean): 當設定為 false 時，SWR 將不會在用戶將瀏覽器窗口重新聚焦（例如，切換回先前最小化的標籤頁）時自動重新驗證數據。默認情況下，SWR 認為用戶回到頁面可能希望看到最新的數據，因此會嘗試重新獲取，設為 false 可以禁用這一行為。
// revalidateOnReconnect (boolean): 當設定為 false 時，SWR 將不會在網絡連接恢復時自動重新驗證數據。這是考慮到，當用戶的設備從無網絡狀態恢復連接時，默認行為是嘗試重新驗證數據，以確保數據的實時性，禁用此選項可減少網絡請求。

export const optionSWRAllFalse = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const optionSWRFocusFalse = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
};

export const optionSWRIfStaleFalse = {
  revalidateIfStale: false,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
};

// ----------------------------------------------------------------------
// Fetch Employees
// ----------------------------------------------------------------------
export const fetchEmployees = async (subordinates = []) => {
  const db = getFirestore();

  // 如果提供了 subordinates，就按照它们筛选员工，否则获取所有活跃员工
  const q = query(
    collection(db, 'employees'),
    where('id', 'in', subordinates),
    where('isActive', '==', true),
    orderBy('id')
  );

  const querySnapshot = await getDocs(q);

  const employees = querySnapshot.docs.map((document) => {
    const employeeData = document.data();
    const { firstName, lastName, annualLeave, ...rest } = employeeData;

    // 計算剩餘天數
    const remainDays =
      annualLeave?.thisRemainingDays === undefined
        ? undefined
        : (annualLeave?.thisRemainingDays || 0) + (annualLeave?.accumulated || 0);

    return {
      name: `${lastName}${firstName}`,
      firstName,
      lastName,
      seniority: annualLeave?.seniority,
      accumulated: annualLeave?.accumulated,
      thisTotalDays: annualLeave?.thisTotalDays,
      thisUsedDays: annualLeave?.thisUsedDays,
      thisRemainingDays: annualLeave?.thisRemainingDays,
      remainDays,
      ...rest,
    };
  });

  // console.log(employees);
  return { employees };
};

export const fetchAllEmployees = async () => {
  const db = getFirestore();

  const q = query(collection(db, 'employees'), orderBy('id'));

  const querySnapshot = await getDocs(q);

  const employees = querySnapshot.docs.map((document) => {
    const employeeData = document.data();
    const { firstName, lastName, annualLeave, ...rest } = employeeData;

    // 計算剩餘天數
    const remainDays =
      annualLeave?.thisRemainingDays === undefined
        ? undefined
        : (annualLeave?.thisRemainingDays || 0) + (annualLeave?.accumulated || 0);

    return {
      name: `${lastName}${firstName}`,
      firstName,
      lastName,
      seniority: annualLeave?.seniority,
      accumulated: annualLeave?.accumulated,
      thisTotalDays: annualLeave?.thisTotalDays,
      thisUsedDays: annualLeave?.thisUsedDays,
      thisRemainingDays: annualLeave?.thisRemainingDays,
      remainDays,
      ...rest,
    };
  });

  // console.log(employees);
  return { employees };
};

export const fetchAllActiveEmployees = async () => {
  const db = getFirestore();

  const q = query(collection(db, 'employees'), where('isActive', '==', true), orderBy('id'));

  const querySnapshot = await getDocs(q);

  const employees = querySnapshot.docs.map((document) => {
    const employeeData = document.data();
    const { firstName, lastName, annualLeave, ...rest } = employeeData;

    // 計算剩餘天數
    const remainDays =
      annualLeave?.thisRemainingDays === undefined
        ? undefined
        : (annualLeave?.thisRemainingDays || 0) + (annualLeave?.accumulated || 0);

    return {
      name: `${lastName}${firstName}`,
      firstName,
      lastName,
      seniority: annualLeave?.seniority,
      accumulated: annualLeave?.accumulated,
      thisTotalDays: annualLeave?.thisTotalDays,
      thisUsedDays: annualLeave?.thisUsedDays,
      thisRemainingDays: annualLeave?.thisRemainingDays,
      remainDays,
      ...rest,
    };
  });

  // console.log(employees);
  return { employees };
};

export const fetchEmployeeByCompanyId = async (companyId) => {
  const db = getFirestore();
  const q = query(
    collection(db, 'employees'),
    where('isActive', '==', true),
    where('companyId', '==', companyId),
    orderBy('id')
  );
  const querySnapshot = await getDocs(q);
  const employees = querySnapshot.docs.map((document) => {
    const employeeData = document.data();
    const { firstName, lastName, annualLeave, ...rest } = employeeData;

    // 計算剩餘天數
    const remainDays =
      annualLeave?.thisRemainingDays === undefined
        ? undefined
        : (annualLeave?.thisRemainingDays || 0) + (annualLeave?.accumulated || 0);

    return {
      name: `${lastName}${firstName}`,
      firstName,
      lastName,
      seniority: annualLeave?.seniority,
      accumulated: annualLeave?.accumulated,
      thisTotalDays: annualLeave?.thisTotalDays,
      thisUsedDays: annualLeave?.thisUsedDays,
      thisRemainingDays: annualLeave?.thisRemainingDays,
      remainDays,
      ...rest,
    };
  });
  return { employees };
};

export const fetchEmployeeById = async (employeeId) => {
  const db = getFirestore();
  const q = query(collection(db, 'employees'), where('id', '==', employeeId));
  const querySnapshot = await getDocs(q);

  console.log('fetchEmployeeById');

  // 檢查是否有結果
  if (!querySnapshot.empty) {
    const document = querySnapshot.docs[0]; // 取得第一筆記錄
    const employeeData = document.data();
    const { firstName, lastName, annualLeave, ...rest } = employeeData;

    // 計算剩餘天數
    const remainDays =
      annualLeave?.thisRemainingDays === undefined
        ? undefined
        : (annualLeave?.thisRemainingDays || 0) + (annualLeave?.accumulated || 0);

    return {
      name: `${lastName}${firstName}`,
      firstName,
      lastName,
      currentPeriod: annualLeave?.currentPeriod,
      currentPeriodStartDate: annualLeave?.currentPeriodStartDate,
      currentPeriodEndDate: annualLeave?.currentPeriodEndDate,
      seniority: annualLeave?.seniority,
      seniorityDecimal: annualLeave?.seniorityDecimal,
      accumulated: annualLeave?.accumulated,
      thisTotalDays: annualLeave?.thisTotalDays,
      thisUsedDays: annualLeave?.thisUsedDays,
      thisRemainingDays: annualLeave?.thisRemainingDays,
      parentalLeaveYears: annualLeave?.parentalLeaveYears,
      originalSeniority: annualLeave?.originalSeniority,
      percentageOfYearPassed: annualLeave?.percentageOfYearPassed,
      daysUntilNextAnniversary: annualLeave?.daysUntilNextAnniversary,
      currentLeaveDays: annualLeave?.currentLeaveDays,
      nextLeaveDays: annualLeave?.nextLeaveDays,
      percentageUntilNextLeaveDays: annualLeave?.percentageUntilNextLeaveDays,
      daysUntilNextLeaveDays: annualLeave?.daysUntilNextLeaveDays,
      remainDays,
      ...rest,
    };
  }

  return null; // 如果沒有找到員工，返回 null 或合適的預設值
};

// ----------------------------------------------------------------------
// Fetch Leaves
// ----------------------------------------------------------------------
export const fetchLeavesByEmployee = async (paramEmployeeId) => {
  try {
    const db = getFirestore();
    const q = query(
      collection(db, 'leaves'),
      where('employeeId', '==', paramEmployeeId),
      where('status', '!=', 'delete'),
      orderBy('status'),
      orderBy('updateDate')
    );
    const querySnapshot = await getDocs(q);
    const leaves = querySnapshot.docs.map((document) => document.data());
    return { leaves };
  } catch (error) {
    console.error('Error fetching leaves: ', error);
    return null;
  }
};

export const fetchLeavesByReviewer = async (employeeId) => {
  try {
    const months = generateHalfYearMonthStrings();

    const db = getFirestore();
    const leavesCollection = collection(db, 'leaves');

    const q = query(
      leavesCollection,
      where('months', 'array-contains-any', months),
      orderBy('updateDate', 'desc')
      // where('status', 'not-in', ['delete', 'draft'])
    );

    const querySnapshot = await getDocs(q);
    const leaves = [];

    querySnapshot.forEach((docLeave) => {
      const data = docLeave.data();
      const initialReview = data.initialReview;
      const secondaryReview = data.secondaryReview;

      if (
        ((initialReview && initialReview.employeeId === employeeId) ||
          (secondaryReview && secondaryReview.employeeId === employeeId)) &&
        !['delete', 'draft'].includes(data.status)
      ) {
        leaves.push(data);
      }
    });

    return { leaves };
  } catch (error) {
    console.error('Error fetching leaves: ', error);
    return null;
  }
};

export const fetchLeaves = async () => {
  try {
    const months = generateHalfYearMonthStrings();

    const db = getFirestore();
    const q = query(
      collection(db, 'leaves'),
      where('months', 'array-contains-any', months),
      // where('status', 'not-in', ['delete', 'draft']),
      // orderBy('status'),
      orderBy('updateDate', 'desc')
    );
    const querySnapshot = await getDocs(q);
    // const leaves = querySnapshot.docs.map((document) => document.data());

    const leaves = querySnapshot.docs
      .map((document) => document.data())
      .filter((data) => !['delete', 'draft'].includes(data.status));

    // console.log('fetchLeaves');

    return { leaves };
  } catch (error) {
    console.error('Error fetching leaves: ', error);
    return null;
  }
};

export const fetchLeavesByDocId = async (docId) => {
  const db = getFirestore();
  const docRef = doc(db, 'leaves', docId);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
    console.log('No such document!');
    return null;
  } catch (error) {
    console.error('Error fetching document: ', error);
    return null;
  }
};

// export const updateBatchLeavesReview = async (rows, reviewStatus, userid) => {
//   const db = getFirestore();
//   const batch = writeBatch(db);

//   rows.forEach((row) => {
//     const { id, initialReview, secondaryReview } = row;

//     // 更新的數據
//     const updatedFields = {};

//     // 檢查 initialReview
//     if (initialReview && initialReview.employeeId === userid) {
//       updatedFields['initialReview.approvedStatus'] = reviewStatus;
//       updatedFields['initialReview.reviceDate'] = serverTimestamp();

//       // 如果 secondaryReview 為 null，更新 row.status
//       if (!secondaryReview) {
//         updatedFields.status = reviewStatus;
//       }
//     }

//     // 檢查 secondaryReview
//     if (secondaryReview && secondaryReview.employeeId === userid) {
//       updatedFields['secondaryReview.approvedStatus'] = reviewStatus;
//       updatedFields['secondaryReview.reviceDate'] = serverTimestamp();

//       // 無論 secondaryReview 是否有初核，更新 row.status
//       updatedFields.status = reviewStatus;
//     }

//     // 如果有需要更新的字段，加入批次
//     if (Object.keys(updatedFields).length > 0) {
//       const docRef = doc(db, 'leaves', id);
//       batch.update(docRef, updatedFields);
//     }
//   });

//   // 提交批次更新
//   try {
//     await batch.commit();
//     console.log('批次更新成功');
//   } catch (error) {
//     console.error('批次更新失敗:', error);
//     throw new Error('Failed to update leaves in batch');
//   }
// };

export const updateBatchReview = async (rows, reviewStatus, userid, collectionName) => {
  const db = getFirestore();
  const batch = writeBatch(db);

  rows.forEach((row) => {
    const { id, initialReview, secondaryReview, isLocked } = row;

    // 如果是 locked 的資料，略過
    if (isLocked) {
      console.log(`Row with id ${id} is locked and will be skipped.`);
      return;
    }

    // 更新的數據
    const updatedFields = {};

    // 檢查 initialReview
    if (initialReview && initialReview.employeeId === userid) {
      updatedFields['initialReview.approvedStatus'] = reviewStatus;
      updatedFields['initialReview.reviceDate'] = serverTimestamp();

      // 如果 secondaryReview 為 null，更新 row.status
      if (!secondaryReview) {
        updatedFields.status = reviewStatus;
      }
    }

    // 檢查 secondaryReview
    if (secondaryReview && secondaryReview.employeeId === userid) {
      updatedFields['secondaryReview.approvedStatus'] = reviewStatus;
      updatedFields['secondaryReview.reviceDate'] = serverTimestamp();

      // 無論 secondaryReview 是否有初核，更新 row.status
      updatedFields.status = reviewStatus;
    }

    // 如果有需要更新的字段，加入批次
    if (Object.keys(updatedFields).length > 0) {
      const docRef = doc(db, collectionName, id);
      batch.update(docRef, updatedFields);
    }
  });

  // 提交批次更新
  try {
    await batch.commit();
    console.log('批次更新成功');
  } catch (error) {
    console.error('批次更新失敗:', error);
    throw new Error('Failed to update leaves in batch');
  }
};

// ----------------------------
// 差勤管理
// ----------------------------

export const addLeaves = async (leavesData) => {
  const db = getFirestore();
  const leavesCollection = collection(db, 'leaves');
  const newLeaves = await addDoc(leavesCollection, leavesData);
  return newLeaves.id;
};

// 附件上传和特定字段更新的综合函数
export const updateLeaveWithAttachments = async (leavesData, updateEmployeeId, onUpdateRow) => {
  const db = getFirestore(); // Firestore 实例
  const storage = getStorage(); // Storage 实例

  let attachmentURLs = [];

  // 检查是否有新附件需要上传
  if (leavesData.attachment && leavesData.attachment.length > 0) {
    const uploadPromises = leavesData.attachment.map(async (fileObjOrUrl) => {
      // 如果是URL，则直接返回，不进行上传
      if (typeof fileObjOrUrl === 'string' && fileObjOrUrl.startsWith('http')) {
        return fileObjOrUrl;
      }

      // 文件上传逻辑
      const randomThreeDigits = Math.floor(Math.random() * 999)
        .toString()
        .padStart(3, '0');
      const originalExtension = fileObjOrUrl.path.split('.').pop(); // 注意这里使用name属性
      const newFileName = `${leavesData.id}-${randomThreeDigits}.${originalExtension}`;
      const storageRef = ref(storage, `${leavesData.employeeId}/${newFileName}`);

      // console.log('fileObjOrUrl', fileObjOrUrl);
      const uploadTask = uploadBytesResumable(storageRef, fileObjOrUrl);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // 可选：添加进度条等UI元素
          },
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    });

    // 等待所有新附件上传完成
    attachmentURLs = await Promise.all(uploadPromises);
  }

  // console.log('attachmentURLs', attachmentURLs);

  // 将新上传的附件URLs与可能存在的旧URLs合并
  // const updatedAttachments = [...(leavesData.attachment || []), ...attachmentURLs].filter(url => typeof url === 'string');

  // console.log('updatedAttachments', updatedAttachments);
  // 更新特定字段

  const docRef = doc(db, 'leaves', leavesData.id);
  try {
    // 更新的欄位
    const updates = {
      attachment: attachmentURLs, // 假設這是已經處理好的新附件URLs
      updateDate: Timestamp.now(),
      updateEmployee: updateEmployeeId,
    };

    await updateDoc(docRef, updates);
    console.log('Leave document successfully updated with attachments and specific fields!');

    // 創建一個新的對象以保存所有的最新資料
    const updatedLeavesData = {
      ...leavesData, // 複製現有的leavesData
      ...updates, // 應用更新
    };

    // 如果onUpdateRow存在，则调用它并传递updatedLeavesData
    if (onUpdateRow) {
      onUpdateRow(updatedLeavesData);
    }
  } catch (error) {
    console.error('Error updating leave document:', error);
  }
};

export const manageLeavesByDocId = async (leavesData, isUpdate = false) => {
  const db = getFirestore();
  const storage = getStorage();

  const attachmentURLs = [];

  // 檢查是否有附件需要上傳
  if (leavesData.attachment && leavesData.attachment.length > 0) {
    const uploadPromises = leavesData.attachment.map(async (fileObjOrUrl) => {
      // 如果是 URL，則直接返回，不進行上傳
      if (typeof fileObjOrUrl === 'string' && fileObjOrUrl.startsWith('http')) {
        return fileObjOrUrl;
      }

      // 生成3位數的亂數
      const randomThreeDigits = Math.floor(Math.random() * 999)
        .toString()
        .padStart(3, '0');

      // 獲取原始文件的副檔名
      const originalExtension = fileObjOrUrl.path.split('.').pop();

      // 生成新的文件名
      const newFileName = `${leavesData.id}-${randomThreeDigits}.${originalExtension}`;

      // 進行文件上傳
      // console.log('fileObjOrUrl', fileObjOrUrl);
      const storageRef = ref(storage, `${leavesData.employeeId}/${newFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, fileObjOrUrl); // 使用 File 物件

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // 可以在這裡添加進度條等 UI 元素
          },
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    });

    // 等待所有文件上傳完成或直接使用現有 URL
    attachmentURLs.push(...(await Promise.all(uploadPromises)));
    leavesData.attachment = attachmentURLs;
  }

  const customDocumentId = leavesData.id;
  const docRef = doc(db, 'leaves', customDocumentId);

  try {
    if (isUpdate) {
      await updateDoc(docRef, leavesData);
      console.log('Document successfully updated!');
    } else {
      await setDoc(docRef, leavesData);
      console.log('Document successfully created!');
    }
  } catch (error) {
    console.error('Error managing document:', error);
  }
};

export const delLeavesByDocId = async (leavesDocId) => {
  const db = getFirestore();
  const docRef = doc(db, 'leaves', leavesDocId); // 注意：參數名稱應為 leavesDocId

  try {
    await deleteDoc(docRef);
    console.log('Document successfully deleted!');
    return true;
  } catch (e) {
    console.error('Error removing document: ', e);
    return false;
  }
};

export const updateLeaveStatusById = async (leavesDocId, newStatus) => {
  const db = getFirestore();
  const docRef = doc(db, 'leaves', leavesDocId);

  try {
    await updateDoc(docRef, {
      status: newStatus,
    });
    console.log(`Document ${leavesDocId} successfully updated to status ${newStatus}!`);
    return true;
  } catch (e) {
    console.error('Error updating document: ', e);
    return false;
  }
};

export const updateLeaveStatusByIds = async (ids, newStatus) => {
  const db = getFirestore();
  const batch = writeBatch(db);

  ids.forEach((id) => {
    const docRef = doc(db, 'leaves', id);
    batch.update(docRef, { status: newStatus });
  });

  try {
    await batch.commit();
    return true;
  } catch (e) {
    console.error('Failed to update the documents:', e);
    return false;
  }
};

export const updateLeaveReviewByIds = async (docId, reviewType, reviewData) => {
  if (!['initialReview', 'secondaryReview'].includes(reviewType)) {
    throw new Error('Invalid reviewType. Should be either "initialReview" or "secondaryReview".');
  }

  const db = getFirestore();
  const docRef = doc(db, 'leaves', docId);

  try {
    // 更新 Firestore 中指定的 docId 和 reviewType
    await updateDoc(docRef, {
      [`${reviewType}`]: reviewData,
    });
    console.log('Document successfully updated!');
    return true;
  } catch (e) {
    console.error('Error updating document: ', e);
    return false;
  }
};

export const fetchAttendanceByEmployee = async (docId, employeeId) => {
  // 確保 docId 和 employeeId 都是有效的
  if (!docId || !employeeId) {
    console.error('Invalid docId or employeeId');
    return null;
  }

  const db = getFirestore();

  // 定義到特定 docId 和 employeeId 的路徑
  const employeeAttendanceCollection = collection(db, `attendances/${docId}/${employeeId}`);

  // 創建查詢，按 datetime 欄位排序
  const q = query(employeeAttendanceCollection);

  try {
    // 執行查詢並獲取所有文件
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No documents found!');
      return null;
    }

    const attendances = [];

    querySnapshot.forEach((document) => {
      attendances.push(document.data());
    });

    return { attendances };
  } catch (error) {
    console.error('Error fetching attendance: ', error);
    return null;
  }
};

// ----------------------------
// 打卡相關
// ----------------------------
export const updateAttendanceByParams = async (
  YearMonth,
  EmployeeId,
  dateString,
  attendanceData
) => {
  // 獲取 Firestore 實例
  const db = getFirestore();

  // 定義要更新的文檔引用
  const docRef = doc(db, 'attendances', YearMonth, EmployeeId, dateString);

  try {
    // 使用 setDoc() 更新 Firestore 文檔。
    await setDoc(docRef, attendanceData);
    console.log('Document successfully updated!');
    return true;
  } catch (e) {
    console.error('Error updating document: ', e);
    return false;
  }
};

export const updateTimesheetCorrectionByDocId = async (correctionData) => {
  const db = getFirestore();
  console.log('correctionData', correctionData);
  const customDocumentId = correctionData.id;
  const docRef = doc(db, 'timesheets', customDocumentId);

  try {
    await setDoc(docRef, correctionData, { merge: true });
    console.log('Document successfully updated or created!');
  } catch (error) {
    console.error('Error updating or creating document: ', error);
  }
};

export async function fetchTimesheetsByEmployee(employeeId) {
  try {
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    const db = getFirestore(); // 獲取 Firestore 實例
    const q = query(
      collection(db, 'timesheets'),
      where('employeeId', '==', employeeId),
      where('correctionDate', '>=', firstDayTimestamp),
      where('correctionDate', '<=', lastDayTimestamp),
      orderBy('correctionDate')
    );

    const querySnapshot = await getDocs(q);

    const timesheets = querySnapshot.docs
      .map((document) => document.data())
      .filter((data) => !['delete'].includes(data.status));

    return { timesheets };
  } catch (error) {
    console.error('Error fetching timesheets by employee:', error);
    return { timesheets: [], error }; // 返回空的 timesheets 陣列和錯誤對象
  }
}

export async function fetchAllTimesheets() {
  try {
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    const db = getFirestore(); // 獲取 Firestore 實例
    const q = query(
      collection(db, 'timesheets'),
      where('correctionDate', '>=', firstDayTimestamp),
      where('correctionDate', '<=', lastDayTimestamp),
      orderBy('correctionDate')
      // where('status', 'not-in', ['delete', 'draft']),
      // orderBy('status'),
    );

    // const q = query(
    //   collection(db, 'timesheets'),
    //   where('status', 'not-in', ['delete', 'draft']),
    //   orderBy('status'),
    //   orderBy('correctionDate')
    // );

    console.log('fetchAllTimesheets');

    const querySnapshot = await getDocs(q);
    const timesheets = querySnapshot.docs
      .map((document) => document.data())
      .filter((data) => !['delete', 'draft'].includes(data.status));

    // console.log('timesheets', timesheets);

    return { timesheets };
  } catch (error) {
    console.error('Error fetching timesheets by employee:', error);
    return { timesheets: [], error }; // 返回空的 timesheets 陣列和錯誤對象
  }
}

export async function fetchTimesheetByDocId(docId) {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'timesheets', docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { timesheet: { id: docSnap.id, ...docSnap.data() } };
    }
    console.warn('No such document!');
    return { timesheet: null };
  } catch (error) {
    console.error('Error fetching timesheet by DocId:', error);
    return { timesheet: null, error };
  }
}

export const updateTimesheetStatusByIds = async (ids, newStatus) => {
  const db = getFirestore(); // 獲取 Firestore 實例
  const batch = writeBatch(db); // 創建一個新的寫入批次

  // 循環遍歷每個 id，並為每個文檔添加一個更新操作
  ids.forEach((id) => {
    const docRef = doc(db, 'timesheets', id); // 獲取每個文檔的引用
    batch.update(docRef, { status: newStatus }); // 在這個批次中，更新該文檔的狀態
  });

  try {
    await batch.commit(); // 提交批次
    return true;
  } catch (e) {
    console.error('Failed to update the documents:', e); // 如果有錯誤，則打印錯誤信息
    return false;
  }
};

export const fetchTimesheetsByReviewer = async (employeeId) => {
  try {
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    const db = getFirestore();
    const leavesCollection = collection(db, 'timesheets');

    const q = query(
      leavesCollection,
      where('correctionDate', '>=', firstDayTimestamp),
      where('correctionDate', '<=', lastDayTimestamp),
      orderBy('correctionDate')
      // where('status', 'not-in', ['delete', 'draft'])
    );

    const querySnapshot = await getDocs(q);
    const timesheets = [];

    querySnapshot.forEach((docLeave) => {
      const data = docLeave.data();
      const initialReview = data.initialReview;
      const secondaryReview = data.secondaryReview;

      if (
        ((initialReview && initialReview.employeeId === employeeId) ||
          (secondaryReview && secondaryReview.employeeId === employeeId)) &&
        !['delete'].includes(data.status)
      ) {
        timesheets.push(data);
      }
    });

    return { timesheets };
  } catch (error) {
    console.error('Error fetching timesheets: ', error);
    return null;
  }
};

export const updateTimesheetByDocId = async (timesheetData) => {
  const db = getFirestore();

  const customDocumentId = timesheetData.id;
  const docRef = doc(db, 'timesheets', customDocumentId);

  // 使用 updateDoc 更新文檔
  // 這將僅更新提供的字段，保留文檔中的其他字段不變
  try {
    await updateDoc(docRef, timesheetData);
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

export const fetchTimesheetsByDocId = async (docId) => {
  const db = getFirestore();
  const docRef = doc(db, 'timesheets', docId);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
    console.log('No such document!');
    return null;
  } catch (error) {
    console.error('Error fetching document: ', error);
    return null;
  }
};

export const updateOvertimesByDocId = async (correctionData) => {
  const db = getFirestore();
  const customDocumentId = correctionData.id;
  const docRef = doc(db, 'overtimes', customDocumentId);

  try {
    await setDoc(docRef, correctionData, { merge: true });
    console.log('Document successfully updated or created!');
  } catch (error) {
    console.error('Error updating or creating document: ', error);
  }
};

export const fetchOvertimesByEmployee = async (paramEmployeeId) => {
  try {
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    // console.log('fetchOvertimesByEmployee');
    const db = getFirestore();
    const q = query(
      collection(db, 'overtimes'),
      where('employeeId', '==', paramEmployeeId),
      where('overtimeDate', '>=', firstDayTimestamp),
      where('overtimeDate', '<=', lastDayTimestamp),
      orderBy('overtimeDate'),
      // where('status', '!=', 'delete'),
      // orderBy('status'),
      orderBy('updateDate')
    );
    const querySnapshot = await getDocs(q);
    // const overtimes = querySnapshot.docs.map((document) => document.data());
    const overtimes = querySnapshot.docs
      .map((document) => document.data())
      .filter((data) => !['delete'].includes(data.status));

    return { overtimes };
  } catch (error) {
    console.error('Error fetching overtimes: ', error);
    return null;
  }
};

export const fetchAllOvertimes = async () => {
  try {
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    const db = getFirestore();
    const q = query(
      collection(db, 'overtimes'),
      where('overtimeDate', '>=', firstDayTimestamp),
      where('overtimeDate', '<=', lastDayTimestamp),
      orderBy('overtimeDate')
    );
    const querySnapshot = await getDocs(q);
    const overtimes = querySnapshot.docs
      .map((document) => document.data())
      .filter((data) => !['delete', 'draft'].includes(data.status));

    // const overtimes = querySnapshot.docs.map((document) => document.data());

    return { overtimes };
  } catch (error) {
    console.error('Error fetching overtimes: ', error);
    return null;
  }
};

export const updateOvertimeStatusByIds = async (ids, newStatus) => {
  const db = getFirestore();
  const batch = writeBatch(db);

  ids.forEach((id) => {
    const docRef = doc(db, 'overtimes', id); // 使用 'overtimes' 作為集合名稱
    batch.update(docRef, { status: newStatus });
  });

  try {
    await batch.commit();
    return true;
  } catch (e) {
    console.error('Failed to update the documents:', e);
    return false;
  }
};

export async function fetchOvertimeByDocId(docId) {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'overtimes', docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { overtime: { id: docSnap.id, ...docSnap.data() } };
    }
    console.warn('No such document!');
    return { overtime: null };
  } catch (error) {
    console.error('Error fetching overtime by DocId:', error);
    return { overtime: null, error };
  }
}

export const fetchOvertimesByReviewer = async (employeeId) => {
  try {
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    const db = getFirestore();
    const overtimesCollection = collection(db, 'overtimes');

    const q = query(
      overtimesCollection,
      where('overtimeDate', '>=', firstDayTimestamp),
      where('overtimeDate', '<=', lastDayTimestamp),
      orderBy('overtimeDate')
      // where('status', 'not-in', ['delete', 'draft'])
    );

    const querySnapshot = await getDocs(q);
    const overtimes = [];

    querySnapshot.forEach((docLeave) => {
      const data = docLeave.data();
      const initialReview = data.initialReview;
      const secondaryReview = data.secondaryReview;

      if (
        ((initialReview && initialReview.employeeId === employeeId) ||
          (secondaryReview && secondaryReview.employeeId === employeeId)) &&
        !['delete'].includes(data.status)
      ) {
        overtimes.push(data);
      }
    });

    return { overtimes };
  } catch (error) {
    console.error('Error fetching overtimes: ', error);
    return null;
  }
};

export const updateOvertimetByDocId = async (overtimeData) => {
  const db = getFirestore();

  const customDocumentId = overtimeData.id;
  const docRef = doc(db, 'overtimes', customDocumentId);

  // 使用 updateDoc 更新文檔
  // 這將僅更新提供的字段，保留文檔中的其他字段不變
  try {
    await updateDoc(docRef, overtimeData);
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

export const logs = async (type, action, employeeId, content) => {
  const db = getFirestore(); // 獲取Firestore實例
  // 獲取裝置資訊
  const deviceInfo = await getDeviceInfo();

  const logData = {
    type,
    action,
    employeeId,
    content,
    deviceInfo,
    createDate: serverTimestamp(), // 使用Firebase的伺服器時間
  };

  // 將數據寫入'logs'集合，並讓Firestore自動生成文檔ID
  await addDoc(collection(db, 'logs'), logData);
};

export async function fetchAllData(yearMonth) {
  try {
    const db = getFirestore(); // 獲取 Firestore 實例

    // 計算當月的第一天和最後一天
    const firstDayOfMonth = new Date(`${yearMonth}-01T00:00:00`);
    const lastDayOfMonth = new Date(
      yearMonth.split('-')[0],
      yearMonth.split('-')[1],
      0,
      23,
      59,
      59
    );

    // 轉為 Firestore Timestamp
    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonth);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonth);

    // 獲取 attendances 的資料
    const attendanceDocRef = doc(db, 'attendances', `${yearMonth}`);
    const attendanceDocSnap = await getDoc(attendanceDocRef);

    // 獲取 timesheets 的資料
    const timesheetQuery = query(
      collection(db, 'timesheets'),
      where('correctionDate', '>=', firstDayTimestamp),
      where('correctionDate', '<=', lastDayTimestamp),
      where('status', '==', 'approved')
    );
    const timesheetQuerySnapshot = await getDocs(timesheetQuery);

    // 獲取 overtimes 的資料
    const overtimeQuery = query(
      collection(db, 'overtimes'),
      where('overtimeDate', '>=', firstDayTimestamp),
      where('overtimeDate', '<=', lastDayTimestamp),
      where('status', '==', 'approved')
    );
    const overtimeQuerySnapshot = await getDocs(overtimeQuery);

    return {
      attendances: attendanceDocSnap.exists() ? attendanceDocSnap.data() : [],
      timesheets: timesheetQuerySnapshot.docs.map((docu) => docu.data()),
      overtimes: overtimeQuerySnapshot.docs.map((docu) => docu.data()),
    };
  } catch (error) {
    console.error('Error fetching data: ', error);
    return {
      attendances: [],
      timesheets: [],
      overtimes: [],
      error: error.message,
    };
  }
}

export async function fetchHrsystemByEmployee(year_month, employeeId) {
  try {
    const db = getFirestore(); // 獲取 Firestore 實例
    const docRef = doc(db, 'hrsystem', year_month); // 定位到特定的 document

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data(); // 獲取 document 的所有資料
      const attendance_data = data.attendance_data || []; // 獲取 attendance_data array
      const last_update = data.last_update; // 獲取 last_update timestamp

      // 找到 id 和 employeeId 匹配的資料
      const targetAttendance = attendance_data.find((att) => att.id === employeeId) || null;

      return { targetAttendance, last_update };
    }

    console.log(`Document ${year_month} does not exist`);
    return { targetAttendance: null, last_update: null };
  } catch (error) {
    console.error('Error fetching attendance by employee:', error);
    return { targetAttendance: null, last_update: null, error }; // 返回 null 和錯誤對象
  }
}

export async function fetchHrsystemAttendance(year_month, employeeIds = null) {
  try {
    const db = getFirestore(); // 獲取 Firestore 實例
    const docRef = doc(db, 'hrsystem', year_month); // 定位到特定的 document

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data(); // 獲取 document 的所有資料
      let attendance_data = data.attendance_data || []; // 獲取 attendance_data array
      const last_update = data.last_update; // 獲取 last_update timestamp

      // 如果 employeeId 被設定，過濾 attendance_data
      if (employeeIds) {
        attendance_data = attendance_data.filter((att) => employeeIds.includes(att.id));
      }

      // 找到 id 和 employeeId 匹配的資料
      // const targetAttendance = attendance_data.find((att) => att.id === employeeId) || null;

      return { attendance_data, last_update };
    }

    console.log(`Document ${year_month} does not exist`);
    return { targetAttendance: null, last_update: null };
  } catch (error) {
    console.error('Error fetching attendance by employee:', error);
    return { targetAttendance: null, last_update: null, error }; // 返回 null 和錯誤對象
  }
}

// export const fetchAllReservations = async () => {
//   const months = generateMonthStrings(); // 假設這個函數已經實現，用於生成月份字符串
//   const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

//   const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart).toMillis(); // 將 Timestamp 轉換為毫秒
//   const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd).toMillis(); // 同上

//   // 初始化 Firestore 資料庫
//   const db = getFirestore();

//   // 定義要查詢的集合名稱
//   const reservationQuery = query(
//     collection(db, 'room_reservations'),
//     where('start', '>=', firstDayTimestamp),
//     where('start', '<=', lastDayTimestamp)
//   );

//   try {
//     // 執行查詢並獲取結果
//     const querySnapshot = await getDocs(reservationQuery);

//     // 初始化一個空陣列來存儲預約資料
//     const reservations = [];

//     // 遍歷查詢結果並將每個預約的資料添加到陣列中
//     querySnapshot.forEach((document) => {
//       const data = document.data();
//       reservations.push(data);
//     });

//     // 返回預約資料
//     return { reservations };
//   } catch (error) {
//     // 輸出錯誤信息並返回 null
//     console.error('Error fetching reservations: ', error);
//     return null;
//   }
// };

export const fetchAllReservations = async () => {
  const months = generateMonthStrings(); // 假設這個函數已經實現，用於生成月份字符串
  const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

  const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart).toMillis(); // 將 Timestamp 轉換為毫秒
  const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd).toMillis(); // 同上

  // 初始化 Firestore 資料庫
  const db = getFirestore();

  // 定義要查詢的集合名稱
  const reservationQuery = query(
    collection(db, 'room_reservations'),
    where('start', '>=', firstDayTimestamp),
    where('start', '<=', lastDayTimestamp)
  );

  try {
    // 執行查詢並獲取結果
    const querySnapshot = await getDocs(reservationQuery);

    // 初始化一個空陣列來存儲預約資料和ID
    const reservations = [];
    const reservationIds = [];

    // 遍歷查詢結果並將每個預約的資料添加到陣列中
    querySnapshot.forEach((document) => {
      const data = document.data();
      reservations.push(data);
      reservationIds.push(document.id); // 收集 reservation 的 ID
    });

    // 如果沒有任何預約，直接返回空結果
    if (reservationIds.length === 0) {
      return { reservations, roomMinutes: [] };
    }

    // 定義批次大小
    const batchSize = 30;
    const roomMinutes = [];

    // 將 reservationIds 分成多個批次進行查詢
    const promises = [];
    for (let i = 0; i < reservationIds.length; i += batchSize) {
      const batchIds = reservationIds.slice(i, i + batchSize);

      const roomMinutesQuery = query(
        collection(db, 'room_minutes'),
        where('reservation_id', 'in', batchIds),
        where('visibility', '==', 'public')
      );

      promises.push(getDocs(roomMinutesQuery));
    }

    // 等待所有批次查詢完成
    const results = await Promise.all(promises);

    // 收集所有批次的查詢結果
    results.forEach((roomMinutesSnapshot) => {
      roomMinutesSnapshot.forEach((document) => {
        const data = document.data();
        roomMinutes.push(data);
      });
    });

    // console.log('roomMinutes', roomMinutes);

    // 返回預約資料和 room_minutes 資料
    return { reservations, roomMinutes };
  } catch (error) {
    // 輸出錯誤信息並返回 null
    console.error('Error fetching reservations and room minutes: ', error);
    return null;
  }
};

export const updateReservationsByDocId = async (reservationData) => {
  const db = getFirestore();
  const customDocumentId = reservationData.id; // 確保這個 id 存在並且是唯一的
  const docRef = doc(db, 'room_reservations', customDocumentId);

  try {
    await setDoc(docRef, reservationData, { merge: true });
    console.log('Document successfully updated or created!');
    return true; // 返回 true 表示成功
  } catch (error) {
    console.error('Error updating or creating document: ', error);
    return false; // 返回 false 表示失敗
  }
};

export const deleteReservationByDocId = async (customDocumentId) => {
  const db = getFirestore();
  const docRef = doc(db, 'room_reservations', customDocumentId);

  try {
    await deleteDoc(docRef);
    console.log('Document successfully deleted!');
    return true; // 返回 true 表示成功
  } catch (error) {
    console.error('Error deleting document: ', error);
    return false; // 返回 false 表示失敗
  }
};

export const updateAuthByDocId = async (authData) => {
  if (!authData.email) {
    console.log('電子郵件為空或未定義，退出函數。');
    return { success: false, errorMessage: '電子郵件為空或未定義' };
  }

  const db = getFirestore();
  const customDocumentId = authData.email;
  const docRef = doc(db, 'auth', customDocumentId);

  try {
    await setDoc(docRef, authData, { merge: false });
    console.log('文件成功更新或創建！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('更新或創建文件時發生錯誤：', error);
    return { success: false, errorMessage: `更新或創建文件時發生錯誤：${error.message}` };
  }
};

// ----------------------------
// 員工管理
// ----------------------------
export const updateEmployeesByDocId = async (employeeData) => {
  const db = getFirestore();
  const customDocumentId = employeeData.id;
  const docRef = doc(db, 'employees', customDocumentId);

  try {
    await setDoc(docRef, employeeData, { merge: true });
    console.log('文件成功更新或創建！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('更新或創建文件時發生錯誤：', error);
    return { success: false, errorMessage: `更新或創建文件時發生錯誤：${error.message}` };
  }
};

export const deleteEmployeeById = async (employeeId) => {
  const db = getFirestore();

  try {
    // 對 auth 集合進行查詢
    const authQuery = query(collection(db, 'auth'), where('id', '==', employeeId));
    const authQuerySnapshot = await getDocs(authQuery);
    authQuerySnapshot.forEach(async (docu) => {
      await deleteDoc(docu.ref);
    });

    // 對 employees 集合進行查詢
    const employeesQuery = query(collection(db, 'employees'), where('id', '==', employeeId));
    const employeesQuerySnapshot = await getDocs(employeesQuery);
    employeesQuerySnapshot.forEach(async (docu) => {
      await deleteDoc(docu.ref);
    });

    console.log('員工資料成功刪除！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('刪除員工資料時發生錯誤：', error);
    return { success: false, errorMessage: `刪除員工資料時發生錯誤：${error.message}` };
  }
};

// ----------------------------------------------------------------------
// 特殊日管理
// ----------------------------------------------------------------------
export const fetchAllSpecialDays = async () => {
  const db = getFirestore();

  const q = query(collection(db, 'special_days'), orderBy('date'));

  const querySnapshot = await getDocs(q);

  const specialdays = querySnapshot.docs.map((document) => {
    const data = document.data();
    const { id, date, description, isWorkday } = data;

    return {
      id,
      date,
      description,
      isWorkday,
    };
  });

  return { specialdays };
};

export const updateSpecialDayByDocId = async (specialDayData) => {
  const db = getFirestore();
  const customDocumentId = specialDayData.id;
  const docRef = doc(db, 'special_days', customDocumentId);

  try {
    await setDoc(docRef, specialDayData, { merge: true });
    console.log('文件成功更新或創建！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('更新或創建文件時發生錯誤：', error);
    return { success: false, errorMessage: `更新或創建文件時發生錯誤：${error.message}` };
  }
};

export const deleteSpecialDayByDocId = async (docId) => {
  const db = getFirestore();
  const docRef = doc(db, 'special_days', docId);

  try {
    await deleteDoc(docRef);
    console.log('文件成功刪除！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('刪除文件時發生錯誤：', error);
    return { success: false, errorMessage: `刪除文件時發生錯誤：${error.message}` };
  }
};

// ----------------------------------------------------------------------
// 部門管理
// ----------------------------------------------------------------------
export const fetchAllDepartments = async () => {
  const db = getFirestore();

  const q = query(collection(db, 'departments'), orderBy('id'));

  const querySnapshot = await getDocs(q);

  const departments = querySnapshot.docs.map((document) => {
    const data = document.data();
    return {
      ...data,
    };
  });

  return { departments };
};

export const fetchDepartmentById = async (documentId) => {
  const db = getFirestore();
  const q = query(collection(db, 'departments'), where('id', '==', documentId));
  const querySnapshot = await getDocs(q);

  // 檢查是否有結果
  if (!querySnapshot.empty) {
    const document = querySnapshot.docs[0]; // 取得第一筆記錄
    const departmentData = document.data();
    return {
      ...departmentData,
    };
  }

  return null; // 如果沒有找到員工，返回 null 或合適的預設值
};

export const updateDepartmentByDocId = async (departmentData) => {
  const db = getFirestore();
  const customDocumentId = departmentData.id;
  const docRef = doc(db, 'departments', customDocumentId);

  // try {
  //   await setDoc(docRef, departmentData, { merge: true });
  //   console.log('Document successfully updated or created!');
  // } catch (error) {
  //   console.error('Error updating or creating document: ', error);
  // }

  try {
    await setDoc(docRef, departmentData, { merge: true });
    console.log('文件成功更新或創建！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('更新或創建文件時發生錯誤：', error);
    return { success: false, errorMessage: `更新或創建文件時發生錯誤：${error.message}` };
  }
};

export const deleteDepartmentByDocId = async (docId) => {
  const db = getFirestore();
  const docRef = doc(db, 'departments', docId);

  try {
    await deleteDoc(docRef);
    console.log('文件成功刪除！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('刪除文件時發生錯誤：', error);
    return { success: false, errorMessage: `刪除文件時發生錯誤：${error.message}` };
  }
};

// ----------------------------------------------------------------------
// PASTA
// ----------------------------------------------------------------------
export const updateInvoiceStatus = async (invoiceIds, status, source, by) => {
  const db = getFirestore();
  const batch = writeBatch(db);

  invoiceIds.forEach((invoiceId) => {
    // const docRef = doc(db, 'pasta_invoices_test', invoiceId);
    const docRef = doc(db, 'pasta_invoices', invoiceId);
    batch.update(docRef, {
      IsVerify: status,
      verifyBy: by,
      verifySource: source,
      verifyDate: serverTimestamp(), // 使用 Firebase 伺服器時間
    });
  });

  try {
    await batch.commit();
    return true;
  } catch (e) {
    console.error('Failed to update the documents:', e);
    return false;
  }
};

// ----------------------------------------------------------------------
// LEAVES CALENDAR
// ----------------------------------------------------------------------

export const fetchLeavesForRecentMonths = async (months) => {
  try {
    // 初始化 Firestore 資料庫
    const db = getFirestore();
    // 使用 'months' 條件進行查詢
    const q = query(collection(db, 'leaves'), where('months', 'array-contains-any', months));

    // 執行查詢
    const querySnapshot = await getDocs(q);

    // 在客戶端過濾掉 'status' 為 'delete' 或 'draft' 的記錄
    const leaves = querySnapshot.docs
      .map((document) => document.data())
      .filter((data) => !['delete', 'draft'].includes(data.status));

    console.log('fetchLeavesForRecentMonths', leaves);

    // 返回過濾後的資料
    return { leaves };
  } catch (error) {
    // 輸出錯誤信息並返回 null
    console.error('Error fetching leaves for recent months: ', error);
    return null;
  }
};

export const fetchLeavesForRecentMonthsAndSettings = async (months) => {
  /**
   * 從 Firestore 中獲取最近幾個月的休假和設置。
   * @param {number[]} months - 最近幾個月的數組。
   * @returns {Promise<{leaves: object[], settings: object|null}>} 包含過濾後的休假和設置的 Promise 對象。
   * @throws {Error} 如果獲取數據時出現錯誤，將拋出錯誤。
   */
  try {
    // 初始化 Firestore 資料庫
    const db = getFirestore();

    // 使用 'months' 條件進行查詢
    const leavesQuery = query(
      collection(db, 'leaves'),
      where('months', 'array-contains-any', months)
    );
    // 定義獲取 _settings/general 文檔的查詢
    const settingsDocRef = doc(db, '_settings/general');

    // 同時執行兩個查詢
    const [querySnapshot, settingsSnapshot] = await Promise.all([
      getDocs(leavesQuery),
      getDoc(settingsDocRef),
    ]);

    // 處理 leaves 查詢結果 (排除 'status' 為 'delete' 或 'draft' 的記錄，以及 'leaveId' 為 'childcare_leave' 或 'unpaid_leave' 的記錄)
    const leaves = querySnapshot.docs
      .map((document) => document.data())
      .filter(
        (data) =>
          !['delete', 'draft'].includes(data.status) &&
          !['childcare_leave', 'unpaid_leave'].includes(data.leaveId)
      );

    // 處理 settings 文檔結果
    const settings = settingsSnapshot.exists() ? settingsSnapshot.data() : null;

    // 返回過濾後的資料和設置
    return { leaves, settings };
  } catch (error) {
    // 輸出錯誤信息並返回 null
    console.error('Error fetching data: ', error);
    return null;
  }
};

export const fetchLeavesTimesheetsOvertimesForRecentMonthsByReviewer = async (reviewer) => {
  try {
    const months = generateMonthStrings(); // 假設這個函數已經實現，用於生成月份字符串
    const { firstDayOfMonthStart, lastDayOfMonthEnd } = getBoundaryDates(); // 假設這個函數已經實現

    const firstDayTimestamp = Timestamp.fromDate(firstDayOfMonthStart);
    const lastDayTimestamp = Timestamp.fromDate(lastDayOfMonthEnd);

    // 初始化 Firestore 資料庫
    const db = getFirestore();

    // 請假單查詢
    const leavesQuery = query(
      collection(db, 'leaves'),
      where('months', 'array-contains-any', months)
    );

    // 補卡單查詢
    const timesheetQuery = query(
      collection(db, 'timesheets'),
      where('correctionDate', '>=', firstDayTimestamp),
      where('correctionDate', '<=', lastDayTimestamp)
    );

    // 加班單查詢
    const overtimeQuery = query(
      collection(db, 'overtimes'),
      where('overtimeDate', '>=', firstDayTimestamp),
      where('overtimeDate', '<=', lastDayTimestamp)
    );

    // 同時執行三個查詢
    const [leavesSnapshot, timesheetsSnapshot, overtimesSnapshot] = await Promise.all([
      getDocs(leavesQuery),
      getDocs(timesheetQuery),
      getDocs(overtimeQuery),
    ]);

    // 初始化三個空數組來收集符合條件的記錄
    const leaves = [];
    const timesheets = [];
    const overtimes = [];

    // 共用的過濾並添加資料的函數
    const filterAndAddData = (snapshot, dataArray) => {
      snapshot.docs.forEach((document) => {
        const data = document.data();
        if (!['delete', 'draft'].includes(data.status)) {
          const initialReview = data.initialReview;
          const secondaryReview = data.secondaryReview;

          // 檢查是否符合審核人條件
          if (
            (initialReview && initialReview.employeeId === reviewer) ||
            (secondaryReview && secondaryReview.employeeId === reviewer)
          ) {
            dataArray.push(data);
          }
        }
      });
    };

    // 處理查詢結果
    filterAndAddData(leavesSnapshot, leaves);
    filterAndAddData(timesheetsSnapshot, timesheets);
    filterAndAddData(overtimesSnapshot, overtimes);

    // 返回過濾後的資料
    const calculateStats = (dataArray, label) => {
      const total = dataArray.length;
      const pending = dataArray.filter((item) => item.status === 'pending').length;
      const approved = dataArray.filter((item) => item.status === 'approved').length;

      return { label, total, pending, approved };
    };

    const leavesStats = calculateStats(leaves, '請假單');
    const timesheetsStats = calculateStats(timesheets, '補卡單');
    const overtimesStats = calculateStats(overtimes, '加班單');

    return [leavesStats, timesheetsStats, overtimesStats];
  } catch (error) {
    console.error('Error fetching data: ', error);
    return null;
  }
};

// ----------------------
export const fetchAllOkrs = async (employeeId) => {
  try {
    // 初始化 Firestore 資料庫
    const db = getFirestore();

    // 公開目標查詢
    const publicObjectiveQuery = query(
      collection(db, 'okr_objectives'),
      where('status', '!=', 'delete'),
      where('visibility', '==', 'public'),
      orderBy('status'),
      orderBy('updatedAt', 'desc')
    );

    // 私有目標查詢，且屬於當前員工
    const privateObjectiveQuery = query(
      collection(db, 'okr_objectives'),
      where('status', '!=', 'delete'),
      where('visibility', '==', 'private'),
      where('owner', '==', employeeId),
      orderBy('status'),
      orderBy('updatedAt', 'desc')
    );

    // 同時執行兩次查詢
    const [publicSnapshot, privateSnapshot] = await Promise.all([
      getDocs(publicObjectiveQuery),
      getDocs(privateObjectiveQuery),
    ]);

    const okrs = [];
    const objectiveIdArray = [];

    // 將查詢結果加入 okrs 陣列
    const addData = (snapshot) => {
      snapshot.docs.forEach((docItem) => {
        // 避免變量名衝突
        const data = docItem.data();
        data.id = docItem.id; // 將 document 的 id 加到 data 中
        okrs.push(data);
        objectiveIdArray.push(data.id); // 將 id 加入到 objectiveIdArray
      });
    };

    // 分別處理公開和私有目標的查詢結果
    addData(publicSnapshot);
    addData(privateSnapshot);

    // 如果有目標 id，則查詢相關的關鍵結果
    const keyresults = [];
    if (objectiveIdArray.length > 0) {
      const keyresultQuery = query(
        collection(db, 'okr_key_results'),
        where('objectiveId', 'in', objectiveIdArray)
      );

      const keyresultSnapshot = await getDocs(keyresultQuery);
      keyresultSnapshot.docs.forEach((docItem) => {
        // 再次避免變量名衝突
        const data = docItem.data();
        keyresults.push({ ...data, id: docItem.id });
      });
    }

    // 將關鍵結果整合到對應的目標中
    okrs.forEach((objective) => {
      objective.keyResults = keyresults.filter((kr) => kr.objectiveId === objective.id);
    });

    // console.log('fetchAllOkrs');

    // 返回整合後的目標和關鍵結果
    return okrs;
  } catch (error) {
    console.error('Error fetching data: ', error);
    return null;
  }
};

export const fetchOkrById = async (id) => {
  try {
    // 初始化 Firestore 資料庫
    const db = getFirestore();

    // 查詢特定 ID 的目標
    const docRef = doc(db, 'okr_objectives', id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log('No such document!');
      return null;
    }

    const okr = docSnap.data();
    okr.id = docSnap.id; // 保存 document 的 id 到 okr 中

    // 如果該 OKR 的狀態不是 'delete'，則繼續處理關鍵結果
    if (okr.status !== 'delete') {
      // 關鍵結果
      const keyResults = [];
      const keyresultQuery = query(
        collection(db, 'okr_key_results'),
        where('objectiveId', '==', id)
      );

      const keyresultSnapshot = await getDocs(keyresultQuery);

      keyresultSnapshot.docs.forEach((item) => {
        const data = item.data();
        keyResults.push({ ...data, id: item.id });
      });

      // 將 keyResults 加入到 okr 中
      okr.keyResults = keyResults;
    }

    // console.log('fetchOkrById', okr);

    // 回傳整理後的資料
    return okr; // 直接返回 okr 對象
  } catch (error) {
    console.error('Error fetching data: ', error);
    return null;
  }
};

export const updateOkr = async (okrsArray, deleteKeyResultIds) => {
  const db = getFirestore();
  const batch = writeBatch(db);

  okrsArray
    .slice()
    .reverse()
    .forEach((okr) => {
      const { keyResults, ...objectiveData } = okr; // 分離keyResults和其他目標資料
      const objectiveRef = doc(db, 'okr_objectives', objectiveData.id); // 獲取目標對象的參考

      keyResults?.forEach((keyResult) => {
        const keyResultRef = doc(db, 'okr_key_results', keyResult.id); // 獲取每個關鍵結果的參考
        // 使用set方法並設置{ merge: true }來更新或創建關鍵結果
        batch.set(keyResultRef, keyResult, { merge: true });
      });

      // 使用set方法並設置{ merge: true }來更新或創建目標資料
      batch.set(objectiveRef, objectiveData, { merge: true });
    });

  // 對 deleteKeyResultIds 中的每個id進行處理
  deleteKeyResultIds.forEach((id) => {
    const keyResultRef = doc(db, 'okr_key_results', id); // 獲取要更新的關鍵結果的參考
    // 將 objectiveId 設為空字元
    batch.update(keyResultRef, { objectiveId: '' });
  });

  // TODO:除了上面更新okr_key_results的docuemnt，也要將 okr_objectives 中，符合 parentKeyResultId == deleteKeyResultIds陣列項目的 document，把這些符合的document parentKeyResultId及parentObjectiveId清除為空白。

  try {
    await batch.commit(); // 提交batch操作
    console.log('Batch update successful');
  } catch (error) {
    console.error('Batch update failed: ', error);
  }
};

export const updateOkrStatusByDocId = async (id, newStatus) => {
  const db = getFirestore();
  const docRef = doc(db, 'okr_objectives', id);

  try {
    await updateDoc(docRef, {
      status: newStatus,
    });
    console.log('Document successfully updated!');
    return true; // 表示成功更新
  } catch (error) {
    console.error('Error updating document: ', error);
    return false; // 表示更新失敗
  }
};

// day 格式 "2024-01-01"
// export const fetchClockRecordsByDay = async (day) => {
//   const db = getFirestore();
//   const docRef = doc(db, 'clock_records', day);

//   console.log('fetchClockRecordsByDay', docRef.path);

//   try {
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       return docSnap.data();
//     }
//     console.log('No such document!');
//     return null;
//   } catch (error) {
//     console.error('Error fetching document: ', error);
//     return null;
//   }
// };

export const fetchClockRecordsByDay = async (day) => {
  const db = getFirestore();
  const collectionRef = collection(db, 'attendances', 'clock_records', day);

  console.log('Fetching records from collection path:', collectionRef.path);

  try {
    const querySnapshot = await getDocs(collectionRef);
    const records = [];

    console.log('Total documents fetched:', querySnapshot.size);

    if (querySnapshot.empty) {
      console.log('No documents found in the collection.');
      return records;
    }

    querySnapshot.forEach((docu) => {
      // console.log('Document ID:', docu.id, '=> Data:', docu.data());
      records.push(docu.data());
    });

    // console.log('Fetched records:', records);

    return records;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return [];
  }
};

// ------------------
// 會議記錄
// ------------------
export const updateOrCreateMinute = async (minuteData) => {
  const db = getFirestore();
  const storage = getStorage();

  const attachmentURLs = [];
  // 檢查是否有附件需要上傳
  if (minuteData.attachment && minuteData.attachment.length > 0) {
    const uploadPromises = minuteData.attachment.map(async (fileObjOrUrl) => {
      // 如果是 URL，則直接返回，不進行上傳
      if (typeof fileObjOrUrl === 'string' && fileObjOrUrl.startsWith('http')) {
        return fileObjOrUrl;
      }

      // 生成3位數的亂數
      const randomThreeDigits = Math.floor(Math.random() * 999)
        .toString()
        .padStart(3, '0');

      // 獲取原始文件的副檔名
      const originalExtension = fileObjOrUrl.path.split('.').pop();

      // 獲取原始文件名
      const originalFileName = fileObjOrUrl.path.split('/').pop();

      // 生成新的文件名
      const newFileName = `${minuteData.id}-${randomThreeDigits}.${originalExtension}`;

      // 進行文件上傳
      const storageRef = ref(storage, `minute/${minuteData.id}/${originalFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, fileObjOrUrl); // 使用 File 物件

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // 可以在這裡添加進度條等 UI 元素
          },
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    });

    // 等待所有文件上傳完成或直接使用現有 URL
    attachmentURLs.push(...(await Promise.all(uploadPromises)));
    minuteData.attachment = attachmentURLs;
  }

  const customDocumentId = minuteData.id; // 假設 minuteData 已經包含了一個 id
  const docRef = doc(db, 'room_minutes', customDocumentId); // 使用 'room_minutes' 作為集合名稱

  try {
    await setDoc(docRef, minuteData, { merge: true });
    console.log('會議記錄成功更新或創建！');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('更新或創建會議記錄時發生錯誤：', error);
    return { success: false, errorMessage: `更新或創建會議記錄時發生錯誤：${error.message}` };
  }
};

export const fetchMinuteByEmployeeId = async (employeeId) => {
  const db = getFirestore();
  const roomMinutesCollection = collection(db, 'room_minutes');

  try {
    // 查詢 visibility == 'public' 且 'status' == 'publish' 的文檔
    const publicQuery = query(
      roomMinutesCollection,
      where('visibility', '==', 'public'),
      where('status', '==', 'publish')
    );
    const publicQuerySnapshot = await getDocs(publicQuery);
    const publicMinutes = publicQuerySnapshot.docs.map((document) => ({
      id: document.id,
      ...document.data(),
    }));

    // 查詢 'status' == 'draft' 且 'recorder' == employeeId 的文檔
    const recorderDraftQuery = query(
      roomMinutesCollection,
      where('status', '==', 'draft'),
      where('recorder', '==', employeeId)
    );
    const recorderDraftQuerySnapshot = await getDocs(recorderDraftQuery);
    const recorderDraftMinutes = recorderDraftQuerySnapshot.docs.map((document) => ({
      id: document.id,
      ...document.data(),
    }));

    // 查詢 visibility == 'private'、'status' == 'publish'，且'recorder'、'meeting_chair' 或 'participants' 陣列裡有 employeeId 的文檔
    const privateQuery = query(
      roomMinutesCollection,
      where('visibility', '==', 'private'),
      where('status', '==', 'publish')
    );
    const privateQuerySnapshot = await getDocs(privateQuery);
    const privateMinutes = privateQuerySnapshot.docs
      .filter((document) => {
        const data = document.data();
        return (
          data.recorder === employeeId ||
          data.meeting_chair === employeeId ||
          data.participants.includes(employeeId)
        );
      })
      .map((document) => ({ id: document.id, ...document.data() }));

    // 合併所有文檔
    const minutes = [...publicMinutes, ...recorderDraftMinutes, ...privateMinutes];

    return { minutes };
  } catch (error) {
    console.error('Error fetching data: ', error);
    return null;
  }
};

// export const fetchMinuteByEmployeeId = async (employeeId) => {
//   const db = getFirestore();
//   const roomMinutesCollection = collection(db, 'room_minutes');

//   try {
//     // 1. 查询 visibility == 'public' 的文档
//     const publicQuery = query(
//       roomMinutesCollection,
//       where('visibility', '==', 'public'),
//       where('status', '==', 'publish')
//     );
//     const publicQuerySnapshot = await getDocs(publicQuery);
//     const publicMinutes = publicQuerySnapshot.docs.map((document) => ({ id: document.id, ...document.data() }));

//     // 2. 查询 visibility == 'private' 并且 recorder == employeeId 或 meeting_chair == employeeId 或 employeeId 在 participants 数组中的文档
//     const privateQuery = query(
//       roomMinutesCollection,
//       where('visibility', '==', 'private'),
//       where('recorder', '==', employeeId),
//       where('meeting_chair', '==', employeeId),
//       where('status', '==', 'publish')
//     );
//     const privateQuerySnapshot = await getDocs(privateQuery);
//     const privateMinutesSpecific = privateQuerySnapshot.docs.map((document) => ({
//       id: document.id,
//       ...document.data(),
//     }));

//     const recorderDraftQuery = query(
//       roomMinutesCollection,
//       where('status', '==', 'draft'),
//       where('recorder', '==', employeeId)
//     );
//     const recorderDraftQuerySnapshot = await getDocs(recorderDraftQuery);
//     const recorderDraftMinutes = recorderDraftQuerySnapshot.docs.map((document) => ({
//       id: document.id,
//       ...document.data(),
//     }));

//     // 查询数组 participants 包含 employeeId 的文档
//     const participantsQuery = query(
//       roomMinutesCollection,
//       where('visibility', '==', 'private'),
//       where('participants', 'array-contains', employeeId)
//     );
//     const participantsQuerySnapshot = await getDocs(participantsQuery);
//     const privateMinutesParticipants = participantsQuerySnapshot.docs.map((document) => ({
//       id: document.id,
//       ...document.data(),
//     }));

//     // 合并所有文档
//     const minutes = [...publicMinutes, ...privateMinutesSpecific, ...privateMinutesParticipants, ...recorderDraftMinutes];

//     return { minutes };
//   } catch (error) {
//     console.error('Error fetching data: ', error);
//     return null;
//   }
// };

export const fetchMinuteByDocId = async (docId) => {
  const db = getFirestore();
  const docRef = doc(db, 'room_minutes', docId);

  try {
    const docSnap = await getDoc(docRef);

    console.log('fetchMinuteByDocId', docRef.path);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    }
    console.log('No such document!');
    return null;
  } catch (error) {
    console.error('Error fetching document: ', error);
    return null;
  }
};

export const updateMinuteStatusById = async (docId, newStatus) => {
  const db = getFirestore();
  const docRef = doc(db, 'room_minutes', docId);

  try {
    await updateDoc(docRef, {
      status: newStatus,
    });
    console.log(`Document ${docId} successfully updated to status ${newStatus}!`);
    return true; // 表示成功更新
  } catch (error) {
    console.error('Error updating document: ', error);
    return false; // 表示更新失敗
  }
};

// -----------------------
// estimates
// -----------------------
// 無上傳附件功能
// export const updateEstimateByDocId = async (estimateData) => {
//   const db = getFirestore(); // 初始化 Firestore
//   const documentId = estimateData.id; // 假設 estimateData 中包含唯一的文檔 ID
//   const docRef = doc(db, 'estimates', documentId); // 指定要更新的集合和文檔 ID

//   try {
//     // 使用 setDoc 更新或創建文檔，merge: true 保留其他字段
//     await setDoc(docRef, estimateData, { merge: false });
//     console.log('Estimate successfully updated or created!');
//   } catch (error) {
//     console.error('Error updating or creating estimate: ', error);
//   }
// };

// 更新估價單並處理附件上傳
export const updateEstimateByDocId = async (estimateData) => {
  const db = getFirestore();
  const storage = getStorage();

  const documentId = estimateData.id; // 假設 estimateData 中包含唯一的文檔 ID
  const docRef = doc(db, 'estimates', documentId); // 指定要更新的集合和文檔 ID

  const attachmentURLs = [];

  // 檢查是否有附件需要上傳
  if (estimateData.attachment && estimateData.attachment.length > 0) {
    const uploadPromises = estimateData.attachment.map(async (fileObjOrUrl) => {
      // 如果是 URL，則直接返回，不進行上傳
      if (typeof fileObjOrUrl === 'string' && fileObjOrUrl.startsWith('http')) {
        return fileObjOrUrl;
      }

      // 獲取原始文件的副檔名
      const originalExtension = fileObjOrUrl.path.split('.').pop();

      // 獲取原始文件名
      const originalFileName = fileObjOrUrl.path.split('/').pop();

      // 進行文件上傳，使用原始文件名
      const storageRef = ref(storage, `estimate/${estimateData.id}/${originalFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, fileObjOrUrl); // 使用 File 物件

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // 可以在這裡添加進度條等 UI 元素
          },
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    });

    // 等待所有文件上傳完成或直接使用現有 URL
    attachmentURLs.push(...(await Promise.all(uploadPromises)));
    estimateData.attachment = attachmentURLs; // 將上傳完成的 URL 更新到 estimateData 中
  }

  try {
    // 使用 setDoc 更新或創建文檔，merge: false 會完全覆蓋原有數據
    await setDoc(docRef, estimateData, { merge: false });
    console.log('Estimate successfully updated or created!');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('Error updating or creating estimate: ', error);
    return {
      success: false,
      errorMessage: `Error updating or creating estimate: ${error.message}`,
    };
  }
};

export async function fetchEstimateByDocId(docId) {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'estimates', docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const estimateData = { id: docSnap.id, ...docSnap.data() };

      // 提取 columns 並按照 preprint, printing, postprint 的順序排序
      const sortedColumns = {};
      const order = ['preprint', 'printing', 'postprint'];

      // 依據指定的順序將 columns 重新排列
      if (estimateData.columns) {
        order.forEach((key) => {
          if (estimateData.columns[key]) {
            sortedColumns[key] = estimateData.columns[key];
          }
        });
      }

      // 更新 estimateData 中的 columns
      estimateData.columns = sortedColumns;

      return { estimate: estimateData };
    }

    console.warn('No such document!');
    return { estimate: null };
  } catch (error) {
    console.error('Error fetching estimate by DocId:', error);
    return { estimate: null, error };
  }
}

export async function fetchEstimatesByDocIds(docIds) {
  const db = getFirestore();

  try {
    // 使用 Promise.all 並行抓取多個文檔
    const fetchPromises = docIds.map(async (docId) => {
      const docRef = doc(db, 'estimates', docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const estimateData = { id: docSnap.id, ...docSnap.data() };

        // 提取並排序 columns
        const sortedColumns = {};
        const order = ['preprint', 'printing', 'postprint'];

        if (estimateData.columns) {
          order.forEach((key) => {
            if (estimateData.columns[key]) {
              sortedColumns[key] = estimateData.columns[key];
            }
          });
        }

        estimateData.columns = sortedColumns;
        return estimateData;
      }

      console.warn(`No such document with ID: ${docId}`);
      return null;
    });

    const results = await Promise.all(fetchPromises);

    // 過濾掉 null 的結果
    return results.filter((item) => item !== null);
  } catch (error) {
    console.error('Error fetching estimates by DocIds:', error);
    return [];
  }
}

export async function fetchEstimatesByEmployeeId(employeeId, customer) {
  try {
    const db = getFirestore(); // 獲取 Firestore 實例

    // 計算一年之前的日期
    const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

    // 查詢 status 為 'publish' 且 createDate >= 一年前，並且 customerName 符合的 documents
    const publishQuery = customer
      ? query(
        collection(db, 'estimates'),
        where('status', '==', 'publish'),
        where('createDate', '>=', oneYearAgo),
        where('basicInfo.customerName', '==', customer) // 使用點語法查找嵌套屬性
      )
      : query(
        collection(db, 'estimates'),
        where('status', '==', 'publish'),
        where('createDate', '>=', oneYearAgo)
      );

    console.log("publishQuery", publishQuery);

    // 查詢 status 為 'draft'，createEmployee 等於 employeeId 並且 customerName 符合的 documents
    const draftQuery = customer
      ? query(
        collection(db, 'estimates'),
        where('status', '==', 'draft'),
        where('createEmployee', '==', employeeId),
        where('basicInfo.customerName', '==', customer) // 使用點語法查找嵌套屬性
      )
      : query(
        collection(db, 'estimates'),
        where('status', '==', 'draft'),
        where('createEmployee', '==', employeeId)
      );

    // 執行兩個查詢
    const [publishSnapshot, draftSnapshot] = await Promise.all([
      getDocs(publishQuery),
      getDocs(draftQuery),
    ]);

    // 將查詢結果映射到 estimates 陣列
    const publishEstimates = publishSnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));

    const draftEstimates = draftSnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));

    // 合併兩個陣列
    const combinedEstimates = [...publishEstimates, ...draftEstimates];

    // 根據 updateDate 進行排序（假設 updateDate 是 Firestore Timestamp 格式）
    combinedEstimates.sort((a, b) => b.updateDate.toMillis() - a.updateDate.toMillis());

    return { estimates: combinedEstimates };
  } catch (error) {
    console.error('Error fetching estimates:', error);
    return { estimates: [], error }; // 返回空的 estimates 陣列和錯誤對象
  }
}

export async function fetchEstimates() {
  try {
    const db = getFirestore(); // 獲取 Firestore 實例

    // 計算一年之前的日期
    const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

    // 查詢 status 為 'publish' 且 createDate >= 一年前的 documents
    const publishQuery = query(
      collection(db, 'estimates'),
      where('status', '==', 'publish'),
      where('createDate', '>=', oneYearAgo)
    );

    console.log("publishQuery", publishQuery);

    // 執行查詢
    const publishSnapshot = await getDocs(publishQuery);

    // 將查詢結果映射到 estimates 陣列
    const publishEstimates = publishSnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));

    // 根據 updateDate 進行排序（假設 updateDate 是 Firestore Timestamp 格式）
    publishEstimates.sort((a, b) => b.updateDate.toMillis() - a.updateDate.toMillis());

    return { estimates: publishEstimates };
  } catch (error) {
    console.error('Error fetching estimates:', error);
    return { estimates: [], error }; // 返回空的 estimates 陣列和錯誤對象
  }
}


export const updateEstimateStatusByIds = async (ids, newStatus) => {
  const db = getFirestore(); // 獲取 Firestore 實例
  const batch = writeBatch(db); // 創建一個新的寫入批次

  // 循環遍歷每個 id，並為每個文檔添加一個更新操作
  ids.forEach((id) => {
    const docRef = doc(db, 'estimates', id); // 獲取每個文檔的引用
    batch.update(docRef, { status: newStatus }); // 在這個批次中，更新該文檔的狀態
  });

  try {
    await batch.commit(); // 提交批次
    return true;
  } catch (e) {
    console.error('Failed to update the documents:', e); // 如果有錯誤，則打印錯誤信息
    return false;
  }
};

// 函數來獲取 supplier 和 client 資料
export async function fetchSupplierAndClient() {
  try {
    const db = getFirestore(); // 初始化 Firestore
    const docRef = doc(db, '_settings', 'supplier_and_customer'); // 參考到 _settings collection 下的 supplier_and_client document
    const docSnap = await getDoc(docRef); // 獲取 document 的快照

    if (docSnap.exists()) {
      const data = { id: docSnap.id, ...docSnap.data() }; // 獲取資料並加上 document ID

      // 檢查是否存在 supplier 和 client 資料
      const supplierData = data.supplier ? data.supplier : [];
      const clientData = data.customer ? data.customer : [];

      // 返回供應商和客戶的資料
      return { supplier: supplierData, customer: clientData };
    }

    console.warn('No such document exists!');
    return { supplier: [], customer: [] }; // 如果 document 不存在，返回空的陣列
  } catch (error) {
    console.error('Error fetching supplier and customer data:', error);
    return { supplier: [], customer: [], error }; // 錯誤處理
  }
}

// Workorder

// 更新估價單並處理附件上傳
export const updateWorkorderByDocId = async (workorderData) => {
  const db = getFirestore();
  const storage = getStorage();

  const documentId = workorderData.id; // 假設 estimateData 中包含唯一的文檔 ID
  const docRef = doc(db, 'workorders', documentId); // 指定要更新的集合和文檔 ID

  const attachmentURLs = [];

  // 檢查是否有附件需要上傳
  if (workorderData.attachment && workorderData.attachment.length > 0) {
    const uploadPromises = workorderData.attachment.map(async (fileObjOrUrl) => {
      // 如果是 URL，則直接返回，不進行上傳
      if (typeof fileObjOrUrl === 'string' && fileObjOrUrl.startsWith('http')) {
        return fileObjOrUrl;
      }

      // 獲取原始文件的副檔名
      const originalExtension = fileObjOrUrl.path.split('.').pop();

      // 獲取原始文件名
      const originalFileName = fileObjOrUrl.path.split('/').pop();

      // 進行文件上傳，使用原始文件名
      const storageRef = ref(storage, `workorder/${workorderData.id}/${originalFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, fileObjOrUrl); // 使用 File 物件

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // 可以在這裡添加進度條等 UI 元素
          },
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    });

    // 等待所有文件上傳完成或直接使用現有 URL
    attachmentURLs.push(...(await Promise.all(uploadPromises)));
    workorderData.attachment = attachmentURLs; // 將上傳完成的 URL 更新到 estimateData 中
  }

  try {
    // 使用 setDoc 更新或創建文檔，merge: false 會完全覆蓋原有數據
    await setDoc(docRef, workorderData, { merge: false });
    console.log('Workorder successfully updated or created!');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('Error updating or creating Workorder: ', error);
    return {
      success: false,
      errorMessage: `Error updating or creating Workorder: ${error.message}`,
    };
  }
};

export async function fetchWorksByEmployeeId(employeeId, customer) {
  try {
    const db = getFirestore(); // 獲取 Firestore 實例

    // 計算一年之前的日期
    const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

    // 查詢 status 為 'publish' 且 createDate >= 一年前，並且 customerName 符合的 documents
    const publishQuery = customer
      ? query(
        collection(db, 'workorders'),
        where('status', '==', 'publish'),
        where('createDate', '>=', oneYearAgo),
        where('customerName', '==', customer) // 使用點語法查找嵌套屬性
      )
      : query(
        collection(db, 'workorders'),
        where('status', '==', 'publish'),
        where('createDate', '>=', oneYearAgo)
      );

    // 查詢 status 為 'draft'，createEmployee 等於 employeeId 並且 customerName 符合的 documents
    const draftQuery = customer
      ? query(
        collection(db, 'workorders'),
        where('status', '==', 'draft'),
        where('createEmployee', '==', employeeId),
        where('customerName', '==', customer) // 使用點語法查找嵌套屬性
      )
      : query(
        collection(db, 'workorders'),
        where('status', '==', 'draft'),
        where('createEmployee', '==', employeeId)
      );

    // 執行兩個查詢
    const [publishSnapshot, draftSnapshot] = await Promise.all([
      getDocs(publishQuery),
      getDocs(draftQuery),
    ]);

    // 將查詢結果映射到 estimates 陣列
    const publishEstimates = publishSnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));

    const draftEstimates = draftSnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));

    // 合併兩個陣列
    const combinedEstimates = [...publishEstimates, ...draftEstimates];

    // 根據 updateDate 進行排序（假設 updateDate 是 Firestore Timestamp 格式）
    combinedEstimates.sort((a, b) => b.updateDate.toMillis() - a.updateDate.toMillis());

    return { workorders: combinedEstimates };
  } catch (error) {
    console.error('Error fetching estimates:', error);
    return { workorders: [], error }; // 返回空的 estimates 陣列和錯誤對象
  }
}

export const updateWorkorderStatusByIds = async (ids, newStatus) => {
  const db = getFirestore(); // 獲取 Firestore 實例
  const batch = writeBatch(db); // 創建一個新的寫入批次

  // 循環遍歷每個 id，並為每個文檔添加一個更新操作
  ids.forEach((id) => {
    const docRef = doc(db, 'workorders', id); // 獲取每個文檔的引用
    batch.update(docRef, { status: newStatus }); // 在這個批次中，更新該文檔的狀態
  });

  try {
    await batch.commit(); // 提交批次
    return true;
  } catch (e) {
    console.error('Failed to update the documents:', e); // 如果有錯誤，則打印錯誤信息
    return false;
  }
};

export async function fetchWorkorderByDocId(docId) {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'workorders', docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const estimateData = { id: docSnap.id, ...docSnap.data() };

      // 提取 columns 並按照 preprint, printing, postprint 的順序排序
      const sortedColumns = {};
      const order = ['preprint', 'printing', 'postprint'];

      // 依據指定的順序將 columns 重新排列
      if (estimateData.columns) {
        order.forEach((key) => {
          if (estimateData.columns[key]) {
            sortedColumns[key] = estimateData.columns[key];
          }
        });
      }

      // 更新 estimateData 中的 columns
      estimateData.columns = sortedColumns;

      return { workorder: estimateData };
    }

    console.warn('No such document!');
    return { workorder: null };
  } catch (error) {
    console.error('Error fetching estimate by DocId:', error);
    return { workorder: null, error };
  }
}

/**
 * 更新指定工作單的任務進度索引
 * @param {string} id - 工作單的唯一 ID
 * @param {number} progressIndex - 要更新的進度索引值
 * @returns {Promise<boolean>} - 是否成功更新
 */
export const updateWorkorderTaskProgressIndexById = async (id, progressIndex) => {
  const db = getFirestore(); // 獲取 Firestore 實例
  const docRef = doc(db, 'workorders', id); // 獲取指定文檔的引用

  try {
    // 更新指定文檔的 taskProgessIndex 欄位
    await updateDoc(docRef, { taskProgessIndex: progressIndex });
    console.log(`Successfully updated taskProgessIndex to ${progressIndex} for workorder ID: ${id}`);
    return true;
  } catch (e) {
    console.error(`Failed to update taskProgessIndex for workorder ID: ${id}`, e);
    return false;
  }
};

// Quotation
// 更新 Quotation 文件
export const updateQuotationByDocId = async (quotationData) => {
  const db = getFirestore();
  const storage = getStorage();

  const documentId = quotationData.id; // Quotation 的唯一識別碼
  const docRef = doc(db, 'quotations', documentId); // 指定集合和文檔

  const attachmentURLs = [];

  // 檢查是否有附件需要上傳
  if (quotationData.attachment && quotationData.attachment.length > 0) {
    const uploadPromises = quotationData.attachment.map(async (fileObjOrUrl) => {
      // 如果是 URL，直接保留
      if (typeof fileObjOrUrl === 'string' && fileObjOrUrl.startsWith('http')) {
        return fileObjOrUrl;
      }

      // 獲取文件的名稱與副檔名
      const originalExtension = fileObjOrUrl.path.split('.').pop();
      const originalFileName = fileObjOrUrl.path.split('/').pop();

      // 設定 Firebase Storage 上傳路徑
      const storageRef = ref(storage, `quotation/${quotationData.id}/${originalFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, fileObjOrUrl);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            console.error('Upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    });

    // 等待所有文件上傳完成，取得 URL
    attachmentURLs.push(...(await Promise.all(uploadPromises)));
    quotationData.attachment = attachmentURLs; // 更新附件 URL
  }

  try {
    // 更新 Firestore 中的 Quotation 文件
    await setDoc(docRef, quotationData, { merge: true }); // merge: true，保留原有資料
    console.log('Quotation successfully updated!');
    return { success: true, errorMessage: '' };
  } catch (error) {
    console.error('Error updating quotation:', error);
    return {
      success: false,
      errorMessage: `Error updating quotation: ${error.message}`,
    };
  }
};


export const updateQuotationsByIds = async (ids, newStatus) => {
  const db = getFirestore(); // 獲取 Firestore 實例
  const batch = writeBatch(db); // 創建批次操作

  try {
    // 遍歷 IDs，將每個 document 加入更新批次
    ids.forEach((id) => {
      const docRef = doc(db, 'quotations', id); // 取得指定集合中的文件引用
      batch.update(docRef, { status: newStatus }); // 在這個批次中，更新該文檔的狀態
    });

    await batch.commit(); // 提交批次操作
    console.log('Quotations updated successfully:', ids);
    return true;
  } catch (error) {
    console.error('Error updating quotations:', error);
    return false;
  }
};

export async function fetchQuotationsByEmployeeId(employeeId, customer) {
  try {
    const db = getFirestore();
    const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

    const publishQuery = customer
      ? query(
        collection(db, 'quotations'),
        where('status', '==', 'publish'),
        where('createDate', '>=', oneYearAgo),
        where('basicInfo.customerName', '==', customer)
      )
      : query(
        collection(db, 'quotations'),
        where('status', '==', 'publish'),
        where('createDate', '>=', oneYearAgo)
      );

    const draftQuery = customer
      ? query(
        collection(db, 'quotations'),
        where('status', '==', 'draft'),
        where('createEmployee', '==', employeeId),
        where('basicInfo.customerName', '==', customer)
      )
      : query(
        collection(db, 'quotations'),
        where('status', '==', 'draft'),
        where('createEmployee', '==', employeeId)
      );

    // 修正重複命名的變數
    const [publishSnap, draftSnap] = await Promise.all([
      getDocs(publishQuery),
      getDocs(draftQuery),
    ]);

    const publishQuotations = publishSnap.docs.map((snapshot) => ({
      id: snapshot.id,
      ...snapshot.data(),
    }));

    const draftQuotations = draftSnap.docs.map((snapshot) => ({
      id: snapshot.id,
      ...snapshot.data(),
    }));

    const combinedQuotations = [...publishQuotations, ...draftQuotations];
    combinedQuotations.sort((a, b) => b.updateDate.toMillis() - a.updateDate.toMillis());

    return { quotations: combinedQuotations };
  } catch (error) {
    console.error('Error fetching quotations:', error);
    return { quotations: [], error };
  }
}

export async function fetchQuotations() {
  try {
    const db = getFirestore();
    const oneYearAgo = Timestamp.fromDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));

    // 查詢 status 為 'publish' 且 createDate >= 一年前的 documents
    const publishQuery = query(
      collection(db, 'quotations'),
      where('status', '==', 'publish'),
      where('createDate', '>=', oneYearAgo)
    );

    // 執行查詢
    const publishSnap = await getDocs(publishQuery);

    // 將查詢結果轉為 JavaScript 對象
    const publishQuotations = publishSnap.docs.map((snapshot) => ({
      id: snapshot.id,
      ...snapshot.data(),
    }));

    // 按 updateDate 排序（降序）
    publishQuotations.sort((a, b) => b.updateDate.toMillis() - a.updateDate.toMillis());

    return { quotations: publishQuotations };
  } catch (error) {
    console.error('Error fetching quotations:', error);
    return { quotations: [], error };
  }
}

export async function fetchQuotationByDocId(docId) {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'quotations', docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const quotationData = { id: docSnap.id, ...docSnap.data() };
      return { quotation: quotationData };
    }

    // 如果文件不存在，直接返回 null
    console.warn(`Quotation with ID ${docId} does not exist.`);
    return { quotation: null };
  } catch (error) {
    console.error('Error fetching quotation by DocId:', error);
    return { quotation: null, error };
  }
}

// ----------------------------------------------------------------------
export const endpoints = {
  employee: {
    list: '/api/employee/list',
    list_by_company: '/api/employee/list_by_company',
    list_by_department: '/api/employee/list_by_department',
    list_all: '/api/employee/list_all',
    details: '/api/employee/details',
    annual: '/api/employee/annual',
  },
  leave: {
    list: '/api/leave/list',
    review: {
      list: '/api/leave/review/list',
    },
  },
  clock: {
    list: '/api/clock/list',
  },
  timesheet: {
    list: 'api/timesheet/list',
    review: {
      list: '/api/timesheet/review/list',
    },
  },
  overtime: {
    list: 'api/overtime/list',
    review: {
      list: '/api/overtime/review/list',
    },
  },
  estimate: {
    list: '/api/estimate/list',
    supplier_and_customer: '/api/estimate/supplier_and_customer',
  },
  hrsystem: {
    main: 'api/hrsystem/main',
    attendance_data: 'api/hrsystem/attendance_data',
  },
  admin: {
    specialdays: 'api/admin/specialdays',
    departments: 'api/admin/departments',
    departments_detail: 'api/admin/departments/details',
  },
  rooms: {
    list: 'api/rooms/list',
    minutes: {
      list: 'api/rooms/minutes/list',
      details: 'api/rooms/minutes/details',
      edit: 'api/rooms/minutes/edit',
    },
  },
  okr: {
    list: 'api/okr/list',
    details: 'api/okr/details',
  },
  managerReview: {
    overview: 'api/managerreview/overview',
  },
};
